body {
  margin: 0;
  font-family: Arial;
}

/* Light - 500 - Normal */
@font-face {
  font-family: "KPMG";
  font-style: normal;
  font-display: swap;
  font-weight: 500;
  src: local("KPMG"), url(./Fonts/KPMG-Light.woff) format("woff");
}

/* Extra-Light - 300 - Normal */
@font-face {
  font-family: "KPMG";
  font-style: normal;
  font-display: swap;
  font-weight: 300;
  src: local("KPMG"), url(./Fonts/KPMG-Extralight.woff) format("woff");
}
